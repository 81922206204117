module.exports = (function() {
    /*
     * Alert dismiss action
     * 1. Get list of all elements that are alert dismiss buttons
     * 2. Add click event listener to these elements
     * 3. When clicked, find the target or parent element with class name "alert"
     * 4. Remove that element from the DOM
     */

    // Start the collapse action on the chosen element
    function doDismiss(event) {
        event.preventDefault();
        // Get target element from data-target attribute
        var targets = getTargets(event);
        var target = targets.dataTarget;

        if (!target) {
            // If data-target not specified, get parent or grandparent node with class="alert"
            var parent = targets.evTarget.parentNode;
            if (parent.classList.contains('alert')) {
                target = parent;
            } else if (parent.parentNode.classList.contains('alert')) {
                target = parent.parentNode;
            }
        }

        fireTrigger(target, 'close.bs.alert');
        target.classList.remove('in');

        function removeElement() {
            // Remove alert from DOM
            try {
                target.parentNode.removeChild(target);
                fireTrigger(target, 'closed.bs.alert');
            } catch (e) {
                window.console.error('Unable to remove alert');
            }
        }

        // Call the complete() function after the transition has finished
        if (transitionend && target.classList.contains('fade')) {
            target.addEventListener(transitionend, function () {
                removeElement();
            }, false);
        } else {
            // For browsers that don't support transitions (e.g. IE9 and lower);
            removeElement();
        }

        return false;
    }

    // Get all alert dismiss buttons and add click event listeners
    var dismissList = document.querySelectorAll('[data-dismiss=alert]');
    for (var j = 0, lenj = dismissList.length; j < lenj; j++) {
        dismissList[j].onclick = doDismiss;
    }
})();