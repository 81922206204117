module.exports = (function () {

    var _toggle = document.getElementById('navigation-toggle');
    var _sectionLinks = document.querySelectorAll('.link--dropdown');
    var _drawers = document.querySelectorAll('.drawer');
    var _drawerContainer = document.getElementById('drawer-container');
    var _drawerSections = document.querySelectorAll('.drawer__section-name');

    /*
    * Attach event listeners if the navigation elements are present on page
     */
    if (_toggle && _sectionLinks && _drawerSections) {
        _toggle.addEventListener('click', toggleMobileMenu);
        for (var a = 0; a < _sectionLinks.length; a++) {
            _sectionLinks[a].onclick = toggleDrawer;
        }
        for (var b = 0; b <  _drawerSections.length; b++) {
            _drawerSections[b].onclick = toggleDrawerSection;
        }

        document.addEventListener('keyup', closeNavigation);
        document.addEventListener('click', closeNavigation);
    }

    /**
     * Toggle showing the mobile menu.
     * All drawers visible
     *
     * @param {object} e
     */
    function toggleMobileMenu(e) {
        _drawerContainer.classList.toggle('drawer-container--open');
        e.preventDefault();
    }

    /**
     * Toggle visibility of the links in a navigation group
     * (mobile navigation)
     *
     * @param {object} e
     */
    function toggleDrawerSection(e) {
        e.target.parentNode.classList.toggle('drawer__section--open');
        e.preventDefault();
    }

    /**
     * Toggle a drawer of a specfic section
     * (desktop navigation)
     *
     * @param {object} e
     */
    function toggleDrawer(e) {
        var drawerId = e.target.getAttribute('data-drawer');
        for (var i = 0; i < _drawers.length; i++) {
            if (_drawers[i].id === drawerId && !_drawers[i].classList.contains('drawer--open')) {
                _drawers[i].classList.add('drawer--open');
            } else {
                closeDrawer(_drawers[i]);
            }
        }
        e.preventDefault();
    }

    /**
     * Put the drawer in a transition state before we close it
     * Removes some abrupt animation behavior
     *
     * @param {object} drawer
     */
    function closeDrawer(drawer) {
        drawer.classList.add('drawer--transitioning');
        setTimeout(function(){
            drawer.classList.remove('drawer--transitioning');
            drawer.classList.remove('drawer--open');
        }, 300);
    }

    function closeNavigation(e) {
        if (e.type == 'keyup' && e.keyCode == 27) {
            closeAllDrawers();
        } else if (e.type == 'click') {

            var classList = e.target.classList;
            if (
                !classList.contains('top-bar__link') &&
                !classList.contains('top-bar__menu') &&
                !classList.contains('drawer') &&
                !classList.contains('drawer__menu') &&
                !classList.contains('drawer__section') &&
                !classList.contains('drawer__sub-menu') &&
                !classList.contains('drawer__language') &&
                !classList.contains('drawer__section-name')
            ) {
                closeAllDrawers();
            }


        }
    }

    function closeAllDrawers() {
        for (var i = 0; i < _drawers.length; i++) {
            _drawers[i].classList.remove('drawer--open');
        }
    }

})();