document.addEventListener('DOMContentLoaded', function() {

    // Load classList shim
    require('./../helpers/classList-shim.js');

    // Load navigation
    require('./../components/navigation.js');

    // My components
    //require('./../components/housingnotice.js');
    require('./../components/maps.js');
    //require('./../components/eventLinks.js');
    //require('./../components/facebook.js');

    // Bootstrap alert dismiss function (since we don't use jquery)
    require('./../components/alert.js');

});