module.exports = (function () {

    // Get the google maps api wrapper
    var GoogleMapsLoader = require('google-maps');

    // Set the API key
    GoogleMapsLoader.KEY = 'AIzaSyDVW9j2UuEuGNh52CbQKchOaL0gcXikn1s';

    // Get the element for the map
    var el = document.getElementById('google-maps');
    if (el) {
        document.addEventListener('scroll', checkVisibility);
    }

    function checkVisibility() {
        if (el.getBoundingClientRect().y < 500) init();
    }

    function init() {
        // Remove the event listener since we only need to load once
        document.removeEventListener('scroll', checkVisibility);

        // Start up the maps api wrapper
        GoogleMapsLoader.load(function (google) {

            // Coordinates we want to focus on
            var location = new google.maps.LatLng(59.859758, 17.634717);

            // Set the map options
            var options = {
                scrollwheel: false,
                center: location,
                zoom: 17,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };

            // Boot the map itself
            var map = new google.maps.Map(el, options);

            // Add a map marker
            var marker = new google.maps.Marker({
                position: location,
                title: '<p>Gotlands Nation</p>',
                map: map
            });
        });
    }


})();