(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
(function(root, factory) {

	if (root === null) {
		throw new Error('Google-maps package can be used only in browser');
	}

	if (typeof define === 'function' && define.amd) {
		define(factory);
	} else if (typeof exports === 'object') {
		module.exports = factory();
	} else {
		root.GoogleMapsLoader = factory();
	}

})(typeof window !== 'undefined' ? window : null, function() {


	'use strict';


	var googleVersion = '3.18';

	var script = null;

	var google = null;

	var loading = false;

	var callbacks = [];

	var onLoadEvents = [];

	var originalCreateLoaderMethod = null;


	var GoogleMapsLoader = {};


	GoogleMapsLoader.URL = 'https://maps.googleapis.com/maps/api/js';

	GoogleMapsLoader.KEY = null;

	GoogleMapsLoader.LIBRARIES = [];

	GoogleMapsLoader.CLIENT = null;

	GoogleMapsLoader.CHANNEL = null;

	GoogleMapsLoader.LANGUAGE = null;

	GoogleMapsLoader.REGION = null;

	GoogleMapsLoader.VERSION = googleVersion;

	GoogleMapsLoader.WINDOW_CALLBACK_NAME = '__google_maps_api_provider_initializator__';


	GoogleMapsLoader._googleMockApiObject = {};


	GoogleMapsLoader.load = function(fn) {
		if (google === null) {
			if (loading === true) {
				if (fn) {
					callbacks.push(fn);
				}
			} else {
				loading = true;

				window[GoogleMapsLoader.WINDOW_CALLBACK_NAME] = function() {
					ready(fn);
				};

				GoogleMapsLoader.createLoader();
			}
		} else if (fn) {
			fn(google);
		}
	};


	GoogleMapsLoader.createLoader = function() {
		script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = GoogleMapsLoader.createUrl();

		document.body.appendChild(script);
	};


	GoogleMapsLoader.isLoaded = function() {
		return google !== null;
	};


	GoogleMapsLoader.createUrl = function() {
		var url = GoogleMapsLoader.URL;

		url += '?callback=' + GoogleMapsLoader.WINDOW_CALLBACK_NAME;

		if (GoogleMapsLoader.KEY) {
			url += '&key=' + GoogleMapsLoader.KEY;
		}

		if (GoogleMapsLoader.LIBRARIES.length > 0) {
			url += '&libraries=' + GoogleMapsLoader.LIBRARIES.join(',');
		}

		if (GoogleMapsLoader.CLIENT) {
			url += '&client=' + GoogleMapsLoader.CLIENT + '&v=' + GoogleMapsLoader.VERSION;
		}

		if (GoogleMapsLoader.CHANNEL) {
			url += '&channel=' + GoogleMapsLoader.CHANNEL;
		}

		if (GoogleMapsLoader.LANGUAGE) {
			url += '&language=' + GoogleMapsLoader.LANGUAGE;
		}

		if (GoogleMapsLoader.REGION) {
			url += '&region=' + GoogleMapsLoader.REGION;
		}

		return url;
	};


	GoogleMapsLoader.release = function(fn) {
		var release = function() {
			GoogleMapsLoader.KEY = null;
			GoogleMapsLoader.LIBRARIES = [];
			GoogleMapsLoader.CLIENT = null;
			GoogleMapsLoader.CHANNEL = null;
			GoogleMapsLoader.LANGUAGE = null;
			GoogleMapsLoader.REGION = null;
			GoogleMapsLoader.VERSION = googleVersion;

			google = null;
			loading = false;
			callbacks = [];
			onLoadEvents = [];

			if (typeof window.google !== 'undefined') {
				delete window.google;
			}

			if (typeof window[GoogleMapsLoader.WINDOW_CALLBACK_NAME] !== 'undefined') {
				delete window[GoogleMapsLoader.WINDOW_CALLBACK_NAME];
			}

			if (originalCreateLoaderMethod !== null) {
				GoogleMapsLoader.createLoader = originalCreateLoaderMethod;
				originalCreateLoaderMethod = null;
			}

			if (script !== null) {
				script.parentElement.removeChild(script);
				script = null;
			}

			if (fn) {
				fn();
			}
		};

		if (loading) {
			GoogleMapsLoader.load(function() {
				release();
			});
		} else {
			release();
		}
	};


	GoogleMapsLoader.onLoad = function(fn) {
		onLoadEvents.push(fn);
	};


	GoogleMapsLoader.makeMock = function() {
		originalCreateLoaderMethod = GoogleMapsLoader.createLoader;

		GoogleMapsLoader.createLoader = function() {
			window.google = GoogleMapsLoader._googleMockApiObject;
			window[GoogleMapsLoader.WINDOW_CALLBACK_NAME]();
		};
	};


	var ready = function(fn) {
		var i;

		loading = false;

		if (google === null) {
			google = window.google;
		}

		for (i = 0; i < onLoadEvents.length; i++) {
			onLoadEvents[i](google);
		}

		if (fn) {
			fn(google);
		}

		for (i = 0; i < callbacks.length; i++) {
			callbacks[i](google);
		}

		callbacks = [];
	};


	return GoogleMapsLoader;

});

},{}],2:[function(require,module,exports){
module.exports = (function() {
    /*
     * Alert dismiss action
     * 1. Get list of all elements that are alert dismiss buttons
     * 2. Add click event listener to these elements
     * 3. When clicked, find the target or parent element with class name "alert"
     * 4. Remove that element from the DOM
     */

    // Start the collapse action on the chosen element
    function doDismiss(event) {
        event.preventDefault();
        // Get target element from data-target attribute
        var targets = getTargets(event);
        var target = targets.dataTarget;

        if (!target) {
            // If data-target not specified, get parent or grandparent node with class="alert"
            var parent = targets.evTarget.parentNode;
            if (parent.classList.contains('alert')) {
                target = parent;
            } else if (parent.parentNode.classList.contains('alert')) {
                target = parent.parentNode;
            }
        }

        fireTrigger(target, 'close.bs.alert');
        target.classList.remove('in');

        function removeElement() {
            // Remove alert from DOM
            try {
                target.parentNode.removeChild(target);
                fireTrigger(target, 'closed.bs.alert');
            } catch (e) {
                window.console.error('Unable to remove alert');
            }
        }

        // Call the complete() function after the transition has finished
        if (transitionend && target.classList.contains('fade')) {
            target.addEventListener(transitionend, function () {
                removeElement();
            }, false);
        } else {
            // For browsers that don't support transitions (e.g. IE9 and lower);
            removeElement();
        }

        return false;
    }

    // Get all alert dismiss buttons and add click event listeners
    var dismissList = document.querySelectorAll('[data-dismiss=alert]');
    for (var j = 0, lenj = dismissList.length; j < lenj; j++) {
        dismissList[j].onclick = doDismiss;
    }
})();
},{}],3:[function(require,module,exports){
module.exports = (function () {

    // Get the google maps api wrapper
    var GoogleMapsLoader = require('google-maps');

    // Set the API key
    GoogleMapsLoader.KEY = 'AIzaSyDVW9j2UuEuGNh52CbQKchOaL0gcXikn1s';

    // Get the element for the map
    var el = document.getElementById('google-maps');
    if (el) {
        document.addEventListener('scroll', checkVisibility);
    }

    function checkVisibility() {
        if (el.getBoundingClientRect().y < 500) init();
    }

    function init() {
        // Remove the event listener since we only need to load once
        document.removeEventListener('scroll', checkVisibility);

        // Start up the maps api wrapper
        GoogleMapsLoader.load(function (google) {

            // Coordinates we want to focus on
            var location = new google.maps.LatLng(59.859758, 17.634717);

            // Set the map options
            var options = {
                scrollwheel: false,
                center: location,
                zoom: 17,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };

            // Boot the map itself
            var map = new google.maps.Map(el, options);

            // Add a map marker
            var marker = new google.maps.Marker({
                position: location,
                title: '<p>Gotlands Nation</p>',
                map: map
            });
        });
    }


})();
},{"google-maps":1}],4:[function(require,module,exports){
module.exports = (function () {

    var _toggle = document.getElementById('navigation-toggle');
    var _sectionLinks = document.querySelectorAll('.link--dropdown');
    var _drawers = document.querySelectorAll('.drawer');
    var _drawerContainer = document.getElementById('drawer-container');
    var _drawerSections = document.querySelectorAll('.drawer__section-name');

    /*
    * Attach event listeners if the navigation elements are present on page
     */
    if (_toggle && _sectionLinks && _drawerSections) {
        _toggle.addEventListener('click', toggleMobileMenu);
        for (var a = 0; a < _sectionLinks.length; a++) {
            _sectionLinks[a].onclick = toggleDrawer;
        }
        for (var b = 0; b <  _drawerSections.length; b++) {
            _drawerSections[b].onclick = toggleDrawerSection;
        }

        document.addEventListener('keyup', closeNavigation);
        document.addEventListener('click', closeNavigation);
    }

    /**
     * Toggle showing the mobile menu.
     * All drawers visible
     *
     * @param {object} e
     */
    function toggleMobileMenu(e) {
        _drawerContainer.classList.toggle('drawer-container--open');
        e.preventDefault();
    }

    /**
     * Toggle visibility of the links in a navigation group
     * (mobile navigation)
     *
     * @param {object} e
     */
    function toggleDrawerSection(e) {
        e.target.parentNode.classList.toggle('drawer__section--open');
        e.preventDefault();
    }

    /**
     * Toggle a drawer of a specfic section
     * (desktop navigation)
     *
     * @param {object} e
     */
    function toggleDrawer(e) {
        var drawerId = e.target.getAttribute('data-drawer');
        for (var i = 0; i < _drawers.length; i++) {
            if (_drawers[i].id === drawerId && !_drawers[i].classList.contains('drawer--open')) {
                _drawers[i].classList.add('drawer--open');
            } else {
                closeDrawer(_drawers[i]);
            }
        }
        e.preventDefault();
    }

    /**
     * Put the drawer in a transition state before we close it
     * Removes some abrupt animation behavior
     *
     * @param {object} drawer
     */
    function closeDrawer(drawer) {
        drawer.classList.add('drawer--transitioning');
        setTimeout(function(){
            drawer.classList.remove('drawer--transitioning');
            drawer.classList.remove('drawer--open');
        }, 300);
    }

    function closeNavigation(e) {
        if (e.type == 'keyup' && e.keyCode == 27) {
            closeAllDrawers();
        } else if (e.type == 'click') {

            var classList = e.target.classList;
            if (
                !classList.contains('top-bar__link') &&
                !classList.contains('top-bar__menu') &&
                !classList.contains('drawer') &&
                !classList.contains('drawer__menu') &&
                !classList.contains('drawer__section') &&
                !classList.contains('drawer__sub-menu') &&
                !classList.contains('drawer__language') &&
                !classList.contains('drawer__section-name')
            ) {
                closeAllDrawers();
            }


        }
    }

    function closeAllDrawers() {
        for (var i = 0; i < _drawers.length; i++) {
            _drawers[i].classList.remove('drawer--open');
        }
    }

})();
},{}],5:[function(require,module,exports){
document.addEventListener('DOMContentLoaded', function() {

    // Load classList shim
    require('./../helpers/classList-shim.js');

    // Load navigation
    require('./../components/navigation.js');

    // My components
    //require('./../components/housingnotice.js');
    require('./../components/maps.js');
    //require('./../components/eventLinks.js');
    //require('./../components/facebook.js');

    // Bootstrap alert dismiss function (since we don't use jquery)
    require('./../components/alert.js');

});
},{"./../components/alert.js":2,"./../components/maps.js":3,"./../components/navigation.js":4,"./../helpers/classList-shim.js":6}],6:[function(require,module,exports){
/*
 * classList.js: Cross-browser full element.classList implementation.
 * 1.1.20150312
 *
 * By Eli Grey, http://eligrey.com
 * License: Dedicated to the public domain.
 *   See https://github.com/eligrey/classList.js/blob/master/LICENSE.md
 */

/*global self, document, DOMException */

/*! @source http://purl.eligrey.com/github/classList.js/blob/master/classList.js */

module.exports = (function () {
    if ("document" in self) {

// Full polyfill for browsers with no classList support
// Including IE < Edge missing SVGElement.classList
        if (!("classList" in document.createElement("_"))
            || document.createElementNS && !("classList" in document.createElementNS("http://www.w3.org/2000/svg", "g"))) {

            (function (view) {

                "use strict";

                if (!('Element' in view)) return;

                var
                    classListProp = "classList"
                    , protoProp = "prototype"
                    , elemCtrProto = view.Element[protoProp]
                    , objCtr = Object
                    , strTrim = String[protoProp].trim || function () {
                            return this.replace(/^\s+|\s+$/g, "");
                        }
                    , arrIndexOf = Array[protoProp].indexOf || function (item) {
                            var
                                i = 0
                                , len = this.length
                                ;
                            for (; i < len; i++) {
                                if (i in this && this[i] === item) {
                                    return i;
                                }
                            }
                            return -1;
                        }
                // Vendors: please allow content code to instantiate DOMExceptions
                    , DOMEx = function (type, message) {
                        this.name = type;
                        this.code = DOMException[type];
                        this.message = message;
                    }
                    , checkTokenAndGetIndex = function (classList, token) {
                        if (token === "") {
                            throw new DOMEx(
                                "SYNTAX_ERR"
                                , "An invalid or illegal string was specified"
                            );
                        }
                        if (/\s/.test(token)) {
                            throw new DOMEx(
                                "INVALID_CHARACTER_ERR"
                                , "String contains an invalid character"
                            );
                        }
                        return arrIndexOf.call(classList, token);
                    }
                    , ClassList = function (elem) {
                        var
                            trimmedClasses = strTrim.call(elem.getAttribute("class") || "")
                            , classes = trimmedClasses ? trimmedClasses.split(/\s+/) : []
                            , i = 0
                            , len = classes.length
                            ;
                        for (; i < len; i++) {
                            this.push(classes[i]);
                        }
                        this._updateClassName = function () {
                            elem.setAttribute("class", this.toString());
                        };
                    }
                    , classListProto = ClassList[protoProp] = []
                    , classListGetter = function () {
                        return new ClassList(this);
                    }
                    ;
// Most DOMException implementations don't allow calling DOMException's toString()
// on non-DOMExceptions. Error's toString() is sufficient here.
                DOMEx[protoProp] = Error[protoProp];
                classListProto.item = function (i) {
                    return this[i] || null;
                };
                classListProto.contains = function (token) {
                    token += "";
                    return checkTokenAndGetIndex(this, token) !== -1;
                };
                classListProto.add = function () {
                    var
                        tokens = arguments
                        , i = 0
                        , l = tokens.length
                        , token
                        , updated = false
                        ;
                    do {
                        token = tokens[i] + "";
                        if (checkTokenAndGetIndex(this, token) === -1) {
                            this.push(token);
                            updated = true;
                        }
                    }
                    while (++i < l);

                    if (updated) {
                        this._updateClassName();
                    }
                };
                classListProto.remove = function () {
                    var
                        tokens = arguments
                        , i = 0
                        , l = tokens.length
                        , token
                        , updated = false
                        , index
                        ;
                    do {
                        token = tokens[i] + "";
                        index = checkTokenAndGetIndex(this, token);
                        while (index !== -1) {
                            this.splice(index, 1);
                            updated = true;
                            index = checkTokenAndGetIndex(this, token);
                        }
                    }
                    while (++i < l);

                    if (updated) {
                        this._updateClassName();
                    }
                };
                classListProto.toggle = function (token, force) {
                    token += "";

                    var
                        result = this.contains(token)
                        , method = result ?
                        force !== true && "remove"
                            :
                        force !== false && "add"
                        ;

                    if (method) {
                        this[method](token);
                    }

                    if (force === true || force === false) {
                        return force;
                    } else {
                        return !result;
                    }
                };
                classListProto.toString = function () {
                    return this.join(" ");
                };

                if (objCtr.defineProperty) {
                    var classListPropDesc = {
                        get: classListGetter
                        , enumerable: true
                        , configurable: true
                    };
                    try {
                        objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
                    } catch (ex) { // IE 8 doesn't support enumerable:true
                        if (ex.number === -0x7FF5EC54) {
                            classListPropDesc.enumerable = false;
                            objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
                        }
                    }
                } else if (objCtr[protoProp].__defineGetter__) {
                    elemCtrProto.__defineGetter__(classListProp, classListGetter);
                }

            }(self));

        } else {
            // There is full or partial native classList support, so just check if we need
            // to normalize the add/remove and toggle APIs.

            (function () {
                "use strict";

                var testElement = document.createElement("_");

                testElement.classList.add("c1", "c2");

                // Polyfill for IE 10/11 and Firefox <26, where classList.add and
                // classList.remove exist but support only one argument at a time.
                if (!testElement.classList.contains("c2")) {
                    var createMethod = function (method) {
                        var original = DOMTokenList.prototype[method];

                        DOMTokenList.prototype[method] = function (token) {
                            var i, len = arguments.length;

                            for (i = 0; i < len; i++) {
                                token = arguments[i];
                                original.call(this, token);
                            }
                        };
                    };
                    createMethod('add');
                    createMethod('remove');
                }

                testElement.classList.toggle("c3", false);

                // Polyfill for IE 10 and Firefox <24, where classList.toggle does not
                // support the second argument.
                if (testElement.classList.contains("c3")) {
                    var _toggle = DOMTokenList.prototype.toggle;

                    DOMTokenList.prototype.toggle = function (token, force) {
                        if (1 in arguments && !this.contains(token) === !force) {
                            return force;
                        } else {
                            return _toggle.call(this, token);
                        }
                    };

                }

                testElement = null;
            }());

        }

    }


})();
},{}]},{},[5])

